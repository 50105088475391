import moment from "moment/moment";
import jwt_decode from "jwt-decode";
import CryptoJS from "crypto-js";
import { basefileURL, css } from "./Consts";
import logo2 from "../Assets/profile.png";
import { mobileCodes } from "./ConstsList";

export const returningValue = (v, type) => {
  if (v && v != "" && v != " " && v != "  " && v != undefined && v != null && v != "null") {
    switch (type) {
      case "Bool": return v == "true" ? true : false;
      case "Date": return new Date(v) != "Invalid Date" ? new Date(v) : "";
      case "DateTime": return v === "Invalid date" ? "-" : v;
      case "Phone":
        let res = v
          ?.replace("+91", "")
          ?.replace(/[^0-9]/g, "")
          ?.replace(/\s+/g, "");
        let l = res?.length;
        if (l <= 5) return res?.replace(/(\d{1})/, "+91 $1", "");
        else if (l > 5 && l <= 10) return res?.replace(/(\d{5})(\d{1})/, "+91 $1 $2", "");
        break;
      case "UniversePhone":
        let str = v?.replace(/(\d{2})(\d{1})/, " $1 $2", "");
        if (str.length >= 10) {
          // return str.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
          return str;
        } else {
          return str;
        }
      case "Email": return v?.replace(/(^\s*)|(\s*$)/, "")?.replace(/[ ]{1,}/gi, "")?.replace(/\n +/, "\n");
      case "Number": return parseInt(v) || 0;
      case "Round": return Math.round(v);
      case "LocalTime": return moment(v, "YYYY-MM-DDTHH:mm:ss").format("YYYY-MMM-DD H:mm:ss");
      // case "LocalTime": return v
      case "LocalTimeOnly": return moment(v, "YYYY-MM-DDTHH:mm:ss").format("H:mm a");
      case "LocalTimes": return moment(v, "YYYY-MM-DDTHH:mm:ss").format("h:mm a");
      case "Array": return Array.isArray(v) && v?.length > 0 ? v : [];
      case "Alphabet":
      case "AlphaNumaric":
      case "NumberString": return v?.toString();
      case "INR": return v.toLocaleString("en-IN", { style: "currency", currency: "INR" });
      case "Aadhaar":
        let res1 = v?.replace(/[^0-9]/g, "");
        let length = res1?.length;
        if (length <= 4) return res1;
        else if (length > 4 && length <= 8) return res1?.replace(/(\d{4})(\d{1})/, "$1 $2");
        else if (length > 8 && length <= 12) return res1?.replace(/(\d{4})(\d{4})(\d{1})/, "$1 $2 $3");
        break;
      default: return v;
    }
  }
  return "";
};

export const decryPtiedLinks = () => {
  let links = sessionStorage.getItem("accessLinks");
  if (links && links != undefined && links != "") {
    return JSON.parse(links);
    var bytes = CryptoJS.AES.decrypt(links, "COLINA");
     return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } else {
    return [];
  }
};

export const CheckLinks = (linkNames) => decryPtiedLinks()?.some((v) => linkNames?.some((a) => a == v));
export const returnErrorColor = (v) => v && v != undefined && v != null && v != "" ? "border border-danger" : "";

export const returnValuewithType = (v, type) => {
  if (v && v != "" && v != undefined && v != null) {
    switch (type) {
      case "Date":
        return new Date(v) != "Invalid Date" ? moment(v).format("MM-DD-YYYY") : v;
      case "Phone":
        let n = v
          ?.replace("+91", "")
          ?.replace(/[^0-9]/g, "")
          ?.replace(/\s+/g, "");
        let l = n?.length;
        if (l <= 5) return n?.replace(/(\d{1})/, "+91 $1", "");
        else if (l > 5 && l <= 10) return n?.replace(/(\d{5})(\d{1})/, "+91 $1 $2", "");
        break;
      case "UniversePhone":
        return v?.toString()?.replace(/[^0-9]/g, "");
      default:
        return v;
    }
  } else return "";
};

export const sortingList = (array, key, type) => array.sort((a, b) => (type == "Number" ? a?.[key] - b?.[key] : type == "Date" ? new Date(a?.[key]) - new Date(b?.[key]) : a?.[key]?.localeCompare(b?.[key])));

export const logOut = () => {
  sessionStorage.clear();
  window.location.pathname = "/";
};

export const handleDownload = (url, file, fileName) => {
  fetch(`${url}${file}/${fileName}`)
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    })
    .catch((error) => console.error("Error downloading file:", error));
};

export const jwtDecodedData = () => {
  if (sessionStorage.getItem("token") && sessionStorage.getItem("token") != "") {
    let res = jwt_decode(sessionStorage.getItem("token"));
    return res;
  }
  return {};
};

export const encryptData = (data) => {
  let en = CryptoJS.AES.encrypt(data?.toString(), "COLINA")?.toString();
  // var encoded = CryptoJS.enc.Base64.parse(en).toString(CryptoJS.enc.Hex);
  // var decoded = CryptoJS.enc.Hex.parse(encoded).toString(CryptoJS.enc.Base64);
  // var decrypted = CryptoJS.AES.decrypt(decoded, "COLINA").toString(CryptoJS.enc.Utf8);
  // let en = CryptoJS.AES.encrypt(data.toString(), 'COLINA', {
  //   mode: CryptoJS.mode.ECB,
  //   padding: CryptoJS.pad.NoPadding
  // }).toString();
  let encoded = CryptoJS.enc.Base64.parse(en).toString(CryptoJS.enc.Hex);
  return encoded;
};

export const decryptData = (encoded) => {
  let temp = {};
  Object.entries(encoded)?.map(([key, value], i) => {
    if (i > 0) {
      let decoded1 = CryptoJS.enc.Hex.parse(value).toString(CryptoJS.enc.Base64);
      let decrypted1 = CryptoJS.AES.decrypt(decoded1, "COLINA").toString(CryptoJS.enc.Utf8);
      temp[key] = decrypted1;
    }
  });
  return temp;
};

export const getUserfromSS = () => JSON.parse(sessionStorage.getItem("user")) || {}
// export const getRoleIdfromSS = () => JSON.parse(sessionStorage.getItem("roleId")) || {}
export const getUserfromSSAccessLinks = () => JSON.parse(sessionStorage.getItem("accessLinks")) || {}

export const formatTimeField = (value) => {
  const intValue = parseInt(value, 10);
  if (isNaN(intValue) || value === "") return "00";
  else if (intValue < 10) return `0${intValue}:00`;
  else if (intValue > 10) return `${intValue}:00`;
  else if (intValue >= 10 && intValue <= 59) return intValue.toString();
  else return "00"
};

const nm = (n) => (n >= 0 && n < 10 ? `0${n}` : n);
export const returnTimeFormat = (d) => `${nm(d?.hours)} : ${nm(d?.minutes)} ${d?.numberOfSubmissions != "" ? `[ ${d?.numberOfSubmissions} ]` : ""}`;

export const getInitialValue = (e) => {
  const savedValue = localStorage.getItem(e);
  return savedValue ? JSON.parse(savedValue) : false;
};

export const getUniqueByKey = (array, key) => [...new Map(array.map((item) => [item[key], item])).values()] || [];

export const buttonColor = (status) => {
  switch (status) {
    case "All": return css.all;
    case "Submitted":
    case "Pending": return css.pending;
    case "Approved":
    case "Completed": return css.approved;
    case "Rejected": return css.reject;
    case "Not Submitted": return css.cancel;
    default: return "defaultColor";
  }
};

export const circularImages = (list, folder) => {
  return (
    <div class="image-container">
      {list?.slice(0, 5)?.map((e) => (e.image ? <img src={`${basefileURL}${folder}/${e?.image}`} alt="Image 1" class="circular-image" title={e?.employeeName ? e?.employeeName : e?.employeeFullName ? e?.employeeFullName : e?.clientName} /> : <img src={logo2} alt="Image 1" class="circular-image" title={e?.employeeName ? e?.employeeName : e?.employeeFullName ? e?.employeeFullName : e?.clientName} />))}
      {list?.length > 5 && <div className="circular-image  d-flex align-items-center bg-white fw-bold text-black" >+{list?.length - 5}</div>}
      {/* {sliceValue == 5 ? <FaPlus size={12} onClick={() => setSliceValue(list?.length)} className="pointer" /> : <GoDash size={12} onClick={() => setSliceValue(5)} className="pointer" />} */}
    </div>
  );
};

export const splitPhoneNumber = (phoneNumber) => {
  let code = mobileCodes?.find(v => phoneNumber?.startsWith(v))
  return code > 0 ? `+${code}  ${phoneNumber?.slice(code.length)}` : phoneNumber
};
